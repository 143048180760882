/* .slider {
  position: relative;
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
} */

@media screen and (min-width: 320px) and (max-width: 480px) {
  .slider {
  position: relative;
  height: 20vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
}

@media screen and (min-width: 481px) and (max-width: 768px)  {
  .slider {
  position: relative;
  height: 30vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
}

@media screen and (min-width: 769px) and (max-width: 1024px)  {
  .slider {
  position: relative;
  height: 38vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
}

@media screen and (min-width: 1025px) and (max-width: 2199px)  {
  .slider {
  position: relative;
  height: 60vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
}

@media screen and (min-width: 2200px) and (max-width: 3388px)  {
  .slider {
  position: relative;
  height: 70vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
}


@media screen and (min-width: 320px) and (max-width: 480px) {
  .image {
    width: 200px;
    height: 100px;
    border-radius: 10px;
  }
  }

@media screen and (min-width: 481px) and (max-width: 768px)  {
.image {
  width: 300px;
  height: 180px;
  border-radius: 10px;
}
}

@media screen and (min-width: 769px) and (max-width: 1024px)  {
  .image {
    width: 500px;
    height: 250px;
    border-radius: 10px;
  }
  }

  @media screen and (min-width: 1025px) and (max-width: 1200px) {
    .image {
      width: 600px;
      height: 350px;
      border-radius: 10px;
    }
    }

  @media screen and (min-width: 1201px) and (max-width: 2199px)  {
      .image {
        width: 650px;
        height: 450px;
        border-radius: 10px;
      }
      }

   @media screen and (min-width: 2201px) and (max-width: 3388px)  {
        .image {
          width: 600px;
          height: 400px;
          border-radius: 10px;
        }
        }

.right-arrow {
  position: absolute;
  top: 50%;
  right: -130px;
  font-size: 3rem;
  color: #000;
  z-index: 10;
  cursor: pointer;
  user-select: none;
}


@media screen and (min-width: 320px) and (max-width: 480px) {
.right-arrow {
  position: absolute;
  top: 44%;
  right: -18px;
  font-size: 1.5rem;
  color: #000;
  z-index: 10;
  cursor: pointer;
  user-select: none;
}
}

@media screen and (min-width: 481px) and (max-width: 768px) {
  .right-arrow {
    position: absolute;
    top: 44%;
    right: -6px;
    font-size: 1.8rem;
    color: #000;
    z-index: 10;
    cursor: pointer;
    user-select: none;
  }
  }

  @media screen and (min-width: 769px) and (max-width: 1024px) {
    .right-arrow {
      position: absolute;
      top: 44%;
      right: 32px;
      font-size: 2.2rem;
      color: #000;
      z-index: 10;
      cursor: pointer;
      user-select: none;
    }
    }

    @media screen and (min-width: 1025px) and (max-width: 1200px) {
      .right-arrow {
        position: absolute;
        top: 44%;
        right: 40px;
        font-size: 2.6rem;
        color: #000;
        z-index: 10;
        cursor: pointer;
        user-select: none;
      }
      }    
  
 @media screen and (min-width: 1201px) and (max-width: 2199px) {
  .right-arrow {
    position: absolute;
    top: 44%;
    right: 132px;
    font-size: 3rem;
    color: #000;
    z-index: 10;
    cursor: pointer;
    user-select: none;
    }
  } 
  
  @media screen and (min-width: 2201px) and (max-width: 3388px) {
    .right-arrow {
      position: absolute;
      top: 44%;
      right: 136px;
      font-size: 3rem;
      color: #000;
      z-index: 10;
      cursor: pointer;
      user-select: none;
      }
    }    

.left-arrow {
  position: absolute;
  top: 50%;
  left: -130px;
  font-size: 3rem;
  color: #000;
  z-index: 10;
  cursor: pointer;
  user-select: none;
}

@media screen and (min-width: 320px) and (max-width: 480px) {
.left-arrow {
  position: absolute;
  top: 44%;
  left: -18px;
  font-size: 1.5rem;
  color: #000;
  z-index: 10;
  cursor: pointer;
  user-select: none;
}
}

@media screen and (min-width: 481px) and (max-width: 768px) {
  .left-arrow {
    position: absolute;
    top: 44%;
    left: -6px;
    font-size: 1.8rem;
    color: #000;
    z-index: 10;
    cursor: pointer;
    user-select: none;
  }
  }

  @media screen and (min-width: 769px) and (max-width: 1024px) {
    .left-arrow {
      position: absolute;
      top: 44%;
      left: 32px;
      font-size: 2.2rem;
      color: #000;
      z-index: 10;
      cursor: pointer;
      user-select: none;
    }
    }  

@media screen and (min-width: 1025px) and (max-width: 1200px) {
  .left-arrow {
    position: absolute;
    top: 44%;
    left: 40px;
    font-size: 2.6rem;
    color: #000;
    z-index: 10;
    cursor: pointer;
    user-select: none;
  }
  } 
  
  @media screen and (min-width: 1201px) and (max-width: 2199px) {
    .left-arrow {
      position: absolute;
      top: 44%;
      left: 132px;
      font-size: 3rem;
      color: #000;
      z-index: 10;
      cursor: pointer;
      user-select: none;
    }
    } 
    
    @media screen and (min-width: 2201px) and (max-width: 3388px) {
      .left-arrow {
        position: absolute;
        top: 44%;
        left: 136px;
        font-size: 3rem;
        color: #000;
        z-index: 10;
        cursor: pointer;
        user-select: none;
      }
      }  

.slide {
  opacity: 0;
  transition-duration: 1s ease;
}

.slide.active {
  opacity: 1;
  transition-duration: 1s;
  transform: scale(1.08);
}




  



