.footer-container {
  background-color: #242424;
  padding: 1rem 0 2rem 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-top: 0.50em double #4141a4;
}

.footer-subscription {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  margin-bottom: 24px;
  padding: 24px;
  color: #fff;
}

.footer-subscription > p {
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
    'Lucida Sans', Arial, sans-serif;
}

.footer-subscription-heading {
  margin-bottom: 24px;
  font-size: 18px;
}

.footer-subscription-text {
  margin-bottom: 24px;
  font-size:16px;
}

.footer-input {
  padding: 8px 20px;
  border-radius: 2px;
  margin-right: 10px;
  outline: none;
  border: none;
  font-size: 18px;
  margin-bottom: 16px;
  border: 1px solid #fff;
}

.footer-links {
  width: 100%;
  max-width: 1000px;
  display: flex;
  justify-content: center;
}

.footer-link-wrapper {
  display: flex;
}

.footer-link-items {
  display: flex;
  flex-direction:row;
  align-items: flex-start;
  text-align: center;
  margin-left: -10px;
  margin-top: 5px;
  margin-bottom: 10px;
  text-indent: 20px;

}

.footer-link-items h2 {
  margin-bottom: 16px;
  font-size: 18px;
}

.footer-link-items > h2 {
  color: #fff;
}

.footer-link-items a {
  color: #fff;
  text-decoration: none;
  margin-bottom: 0.5rem;
}

.footer-link-items a:hover {
  color: #e9e9e9;
  transition: 0.3s ease-out;
}

.footer-email-form h2 {
  margin-bottom: 2rem;
}

.footer-input::placeholder {
  color: #b1b1b1;
}

/* Social Icons */
.social-icon-link {
  color: #fff;
  font-size: 24px;
}

.social-media {
  max-width: 1000px;
  width: 100%;
}

.social-media-wrap {
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  width: 100%;
  max-width: 1000px;
  margin: 40px auto 0 auto;
}

.social-icons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 240px;
}

.social-logo {
  color: #fff;
  justify-self: start;
  cursor: pointer;
  text-decoration: none;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.website-rights {
  color: #fff;
  letter-spacing: 2px;
  text-align: center;
}

@media only screen and (min-width: 320px) and (max-width: 480px) {
  .footer-container {
    background-color: #242424;
    padding: 1rem 0 2rem 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-top: 0.50em double #4141a4;
  }
  
  .footer-subscription {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  
    margin-bottom: 24px;
    padding: 24px;
    color: #fff;
  }
  
  .footer-subscription > p {
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
      'Lucida Sans', Arial, sans-serif;
  }
  
  .footer-subscription-heading {
    margin-bottom: 24px;
    font-size: 14px;
  }
  
  .footer-subscription-text {
    margin-bottom: 24px;
    font-size: 12px;
  }
  
  .footer-input {
    padding: 8px 20px;
    border-radius: 2px;
    margin-right: 10px;
    outline: none;
    border: none;
    font-size: 18px;
    margin-bottom: 16px;
    border: 1px solid #fff;
  }

  .footer-links {
    padding-top: 2rem;
  }

  .footer-input {
    width: 100%;
  }

  .btn {
    width: 100%;
  }

  .footer-link-wrapper {
    flex-direction: row;
  }

  .social-media-wrap {
    flex-direction: row;
  }

  .website-rights {
    color: #fff;
    letter-spacing: 1px;
    font-size: 10px;
    text-align: center;
  }

  .website-design {
    color: #585555;
    letter-spacing: 1px;
    font-size: 8px;
    text-align: center;
    font-weight: lighter;
    font-style: italic;
  }

  
}

@media only screen and (min-width: 481px) and (max-width: 768px) {
  .footer-container {
    background-color: #242424;
    padding: 1rem 0 2rem 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-top: 0.50em double #4141a4;
  }
  
  .footer-subscription {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  
    margin-bottom: 24px;
    padding: 24px;
    color: #fff;
  }
  
  .footer-subscription > p {
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
      'Lucida Sans', Arial, sans-serif;
  }
  
  .footer-subscription-heading {
    margin-bottom: 24px;
    font-size: 1px;
  }
  
  .footer-subscription-text {
    margin-bottom: 24px;
    font-size:14px;
  }
  
  .footer-input {
    padding: 8px 20px;
    border-radius: 2px;
    margin-right: 10px;
    outline: none;
    border: none;
    font-size: 18px;
    margin-bottom: 16px;
    border: 1px solid #fff;
  }

  .footer-links {
    padding-top: 2rem;
  }

  .footer-input {
    width: 100%;
  }

  .btn {
    width: 100%;
  }

  .footer-link-wrapper {
    flex-direction: row;
  }

  .social-media-wrap {
    flex-direction: column;
  }

  .website-rights {
    color: #fff;
    letter-spacing: 1px;
    font-size: 12px;
    text-align: center;
  }

  .website-design {
    color: #585555;
    letter-spacing: 1px;
    font-size: 8px;
    text-align: center;
    font-weight: lighter;
    font-style: italic;
  }
}

@media only screen and (min-width: 769px) and (max-width: 1024px) {
  .footer-container {
    background-color: #242424;
    padding: 1rem 0 2rem 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-top: 0.50em double #4141a4;
  }
  
  .footer-subscription {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  
    margin-bottom: 24px;
    padding: 24px;
    color: #fff;
  }
  
  .footer-subscription > p {
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
      'Lucida Sans', Arial, sans-serif;
  }
  
  .footer-subscription-heading {
    margin-bottom: 24px;
    font-size: 1px;
  }
  
  .footer-subscription-text {
    margin-bottom: 24px;
    font-size:14px;
  }
  
  .footer-input {
    padding: 8px 20px;
    border-radius: 2px;
    margin-right: 10px;
    outline: none;
    border: none;
    font-size: 18px;
    margin-bottom: 16px;
    border: 1px solid #fff;
  }

  .footer-links {
    padding-top: 2rem;
  }

  .footer-input {
    width: 100%;
  }

  .btn {
    width: 100%;
  }

  .footer-link-wrapper {
    flex-direction: row;
  }

  .social-media-wrap {
    flex-direction: column;
  }

  .website-rights {
    color: #fff;
    letter-spacing: 1px;
    font-size: 12px;
    text-align: center;
  }

  .website-design {
    color: #585555;
    letter-spacing: 1px;
    font-size: 8px;
    text-align: center;
    font-weight: lighter;
    font-style: italic;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1200px) {
  .footer-container {
    background-color: #242424;
    padding: 1rem 0 2rem 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-top: 0.50em double #4141a4;
  }
  
  .footer-subscription {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  
    margin-bottom: 24px;
    padding: 24px;
    color: #fff;
  }
  
  .footer-subscription > p {
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
      'Lucida Sans', Arial, sans-serif;
  }
  
  .footer-subscription-heading {
    margin-bottom: 24px;
    font-size: 1px;
  }
  
  .footer-subscription-text {
    margin-bottom: 24px;
    font-size:14px;
  }
  
  .footer-input {
    padding: 8px 20px;
    border-radius: 2px;
    margin-right: 10px;
    outline: none;
    border: none;
    font-size: 18px;
    margin-bottom: 16px;
    border: 1px solid #fff;
  }

  .footer-links {
    padding-top: 2rem;
  }

  .footer-input {
    width: 100%;
  }

  .btn {
    width: 100%;
  }

  .footer-link-wrapper {
    flex-direction: row;
  }

  .social-media-wrap {
    flex-direction: column;
  }

  .website-rights {
    color: #fff;
    letter-spacing: 1px;
    font-size: 12px;
    text-align: center;
  }

  .website-design {
    color: #585555;
    letter-spacing: 1px;
    font-size: 8px;
    text-align: center;
    font-weight: lighter;
    font-style: italic;
  }
}

@media screen and (min-width: 1201px) and (max-width: 2199px) {
  .footer-container {
    background-color: #242424;
    padding: 1rem 0 2rem 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-top: 0.50em double #4141a4;
  }
  
  .footer-subscription {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  
    margin-bottom: 24px;
    padding: 24px;
    color: #fff;
  }
  
  .footer-subscription > p {
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
      'Lucida Sans', Arial, sans-serif;
  }
  
  .footer-subscription-heading {
    margin-bottom: 24px;
    font-size: 1px;
  }
  
  .footer-subscription-text {
    margin-bottom: 24px;
    font-size:14px;
  }
  
  .footer-input {
    padding: 8px 20px;
    border-radius: 2px;
    margin-right: 10px;
    outline: none;
    border: none;
    font-size: 18px;
    margin-bottom: 16px;
    border: 1px solid #fff;
  }

  .footer-links {
    padding-top: 2rem;
  }

  .footer-input {
    width: 100%;
  }

  .btn {
    width: 100%;
  }

  .footer-link-wrapper {
    flex-direction: row;
  }

  .social-media-wrap {
    flex-direction: column;
  }

  .website-rights {
    color: #fff;
    letter-spacing: 1px;
    font-size: 12px;
    text-align: center;
  }

  .website-design {
    color: #585555;
    letter-spacing: 1px;
    font-size: 8px;
    text-align: center;
    font-weight: lighter;
    font-style: italic;
  }
}

@media screen and (min-width: 2200px) and (max-width: 3388px) {
  .footer-container {
    background-color: #242424;
    padding: 1rem 0 2rem 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-top: 0.50em double #4141a4;
  }
  
  .footer-subscription {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  
    margin-bottom: 24px;
    padding: 24px;
    color: #fff;
  }
  
  .footer-subscription > p {
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
      'Lucida Sans', Arial, sans-serif;
  }
  
  .footer-subscription-heading {
    margin-bottom: 24px;
    font-size: 1px;
  }
  
  .footer-subscription-text {
    margin-bottom: 24px;
    font-size:14px;
  }
  
  .footer-input {
    padding: 8px 20px;
    border-radius: 2px;
    margin-right: 10px;
    outline: none;
    border: none;
    font-size: 18px;
    margin-bottom: 16px;
    border: 1px solid #fff;
  }

  .footer-links {
    padding-top: 2rem;
  }

  .footer-input {
    width: 100%;
  }

  .btn {
    width: 100%;
  }

  .footer-link-wrapper {
    flex-direction: row;
  }

  .social-media-wrap {
    flex-direction: column;
  }

  .website-rights {
    color: #fff;
    letter-spacing: 1px;
    font-size: 18px;
    text-align: center;
  }

  .website-design {
    color: #585555;
    letter-spacing: 1px;
    font-size: 16px;
    text-align: center;
    font-weight: lighter;
    font-style: italic;
  }
}






@media only screen and (min-width: 320px) and (max-width: 480px) {
  .footer-links {
  width: 100%;
  max-width: 1000px;
  display: flex;
  justify-content: center;
}

.footer-link-wrapper {
  display: flex;
}

.footer-link-items {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  text-align: center;
  margin-left: -10px;
  margin-top: 5px;
  margin-bottom: 10px;
  text-indent: 10px;
}

.footer-link-items h2 {
  margin-bottom: 16px;
  font-size: 12px;
}

.footer-link-items > h2 {
  color: #fff;
}

.footer-link-items a {
  color: #fff;
  text-decoration: none;
  margin-bottom: 0.5rem;
}
}

@media only screen and (min-width: 481px) and (max-width: 768px) {
  .footer-links {
    width: 100%;
    max-width: 1000px;
    display: flex;
    justify-content: center;
  }
  
  .footer-link-wrapper {
    display: flex;
  }
  
  .footer-link-items {
    display: flex;
    flex-direction:row;
    align-items: flex-start;
    text-align: center;
    margin-left: -40px;
    margin-top: 5px;
    margin-bottom: 10px;
    font-size: 18px;
    text-indent: 40px;
  }
  
  .footer-link-items h2 {
    margin-bottom: 16px;
    font-size: 18px;
  }
  
  .footer-link-items > h2 {
    color: #fff;
  }
  
  .footer-link-items a {
    color: #fff;
    text-decoration: none;
    margin-bottom: 0.5rem;
  }
  }

  @media only screen and (min-width: 769px) and (max-width: 1024px) {
    .footer-links {
      width: 100%;
      max-width: 1000px;
      display: flex;
      justify-content: center;
    }
    
    .footer-link-wrapper {
      display: flex;
    }
    
    .footer-link-items {
      display: flex;
      flex-direction:row;
      align-items: flex-start;
      text-align: center;
      margin-left: -40px;
      margin-top: 5px;
      margin-bottom: 10px;
      font-size: 18px;
      text-indent: 40px;
    }
    
    .footer-link-items h2 {
      margin-bottom: 16px;
      font-size: 18px;
    }
    
    .footer-link-items > h2 {
      color: #fff;
    }
    
    .footer-link-items a {
      color: #fff;
      text-decoration: none;
      margin-bottom: 0.5rem;
    }
    }

    @media screen and (min-width: 1025px) and (max-width: 1200px) {
      .footer-links {
        width: 100%;
        max-width: 1000px;
        display: flex;
        justify-content: center;
      }
      
      .footer-link-wrapper {
        display: flex;
      }
      
      .footer-link-items {
        display: flex;
        flex-direction:row;
        align-items: flex-start;
        text-align: center;
        margin-left: -40px;
        margin-top: 5px;
        margin-bottom: 10px;
        font-size: 18px;
        text-indent: 40px;
      }
      
      .footer-link-items h2 {
        margin-bottom: 16px;
        font-size: 18px;
      }
      
      .footer-link-items > h2 {
        color: #fff;
      }
      
      .footer-link-items a {
        color: #fff;
        text-decoration: none;
        margin-bottom: 0.5rem;
      }
      }

 @media screen and (min-width: 1201px) and (max-width: 2199px) {
  .footer-links {
    width: 100%;
    max-width: 1000px;
    display: flex;
    justify-content: center;
  }
  
  .footer-link-wrapper {
    display: flex;
  }
  
  .footer-link-items {
    display: flex;
    flex-direction:row;
    align-items: flex-start;
    text-align: center;
    margin-left: -40px;
    margin-top: 5px;
    margin-bottom: 10px;
    font-size: 18px;
    text-indent: 40px;
  }
  
  .footer-link-items h2 {
    margin-bottom: 16px;
    font-size: 18px;
  }
  
  .footer-link-items > h2 {
    color: #fff;
  }
  
  .footer-link-items a {
    color: #fff;
    text-decoration: none;
    margin-bottom: 0.5rem;
  }
  }

  @media screen and (min-width: 2200px) and (max-width: 3388px) {
    .footer-links {
      width: 100%;
      max-width: 1000px;
      display: flex;
      justify-content: center;
    }
    
    .footer-link-wrapper {
      display: flex;
    }
    
    .footer-link-items {
      display: flex;
      flex-direction:row;
      align-items: flex-start;
      text-align: center;
      margin-left: -40px;
      margin-top: 5px;
      margin-bottom: 10px;
      font-size: 28px;
      text-indent: 40px;
    }
    
    .footer-link-items h2 {
      margin-bottom: 16px;
      font-size: 18px;
    }
    
    .footer-link-items > h2 {
      color: #fff;
    }
    
    .footer-link-items a {
      color: #fff;
      text-decoration: none;
      margin-bottom: 0.5rem;
    }
    }  






/* @media screen and (max-width: 768px) {
} */