@import url('https://fonts.googleapis.com/css?family=Anton');
@import url('https://fonts.googleapis.com/css?family=Audiowide');
@import url('https://fonts.googleapis.com/css?family=Aldrich');


video {
    object-fit: cover;
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: -1;
}


.hero-container {
    background: url('../../public/images/CoverPhoto_welder.jpeg') center center/cover
    no-repeat;
    height: 70vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
    object-fit: cover;
    
}


@media screen and (min-width: 320px) and (max-width: 481px) {
.hero-container > h1 {
  color: #fff;
  margin-top: -18px;
  font-size: 36px;
  text-shadow: 4px 4px rgba(0.5, 0.5, 0.5, 0.5);
  font-family: 'Anton';
}
}
@media screen and (min-width: 481px) and (max-width: 768px) {
  .hero-container > h1 {
    color: #fff;
    margin-top: 10px;
    font-size: 54px;
    text-shadow: 4px 4px rgba(0.5, 0.5, 0.5, 0.5);
    font-family: 'Anton';
  }
  }

  @media screen and (min-width: 769px) and (max-width: 1024px) {
    .hero-container > h1 {
      color: #fff;
      margin-top: 8px;
      font-size: 64px;
      text-shadow: 4px 4px rgba(0.5, 0.5, 0.5, 0.5);
      font-family: 'Anton';
    }
    }

    @media screen and (min-width: 1025px) and (max-width: 1200px) {
      .hero-container > h1 {
        color: #fff;
        margin-top: 8px;
        font-size: 74px;
        text-shadow: 4px 4px rgba(0.5, 0.5, 0.5, 0.5);
        font-family: 'Anton';
      }
      }  

      @media screen and (min-width: 1201px) and (max-width: 2199px) {
        .hero-container > h1 {
          color: #fff;
          margin-top: 8px;
          font-size: 74px;
          text-shadow: 4px 4px rgba(3.5, 3.5, 3.5, 3.5);
          font-family: 'Anton';
        }
        }
        @media screen and (min-width: 2200px) and (max-width: 3388px) {
          .hero-container > h1 {
            color: #fff;
            margin-top: 8px;
            font-size: 74px;
            text-shadow: 4px 4px rgba(0.5, 0.5, 0.5, 0.5);
            font-family: 'Anton';
          }
          }  

@media screen and (min-width: 320px) and (max-width: 480px) {
.hero-links > p {
    display: contents;
    text-align: left;
    text-shadow: 2px 2px rgba(1.5, 1.5,  1.5, 1.5);
    color: #fff;
    font-weight: 600;
    font-size: 22px;
    font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif

}   
}

@media screen and (min-width: 481px) and (max-width: 768px) {
  .hero-links > p {
      display: contents;
      text-align: left;
      text-shadow: 2px 2px rgba(1.5, 1.5,  1.5, 1.5);
      color: #fff;
      font-weight: 600;
      font-size: 28px;
      font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif
  
  }   
  }

  @media screen and (min-width: 769px) and (max-width: 1024px) {
    .hero-links > p {
        display: contents;
        text-align: left;
        text-shadow: 2px 2px rgba(1.5, 1.5,  1.5, 1.5);
        color: #fff;
        font-weight: 600;
        font-size: 28px;
        font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif
    
    }   
    }

    

    @media screen and (min-width: 1025px) and (max-width: 1200px) {
      .hero-links > p {
          display: contents;
          text-align: left;
          text-shadow: 2px 2px rgba(1.5, 1.5,  1.5, 1.5);
          color: #fff;
          font-weight: 600;
          font-size: 36px;
          font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif
      
      }   
      }

      @media screen and (min-width: 1201px) and (max-width: 2199px) {
        .hero-links > p {
            display: contents;
            text-align: left;
            text-shadow: 2px 2px rgba(2.5, 2.5,  2.5, 2.5);
            color: #fff;
            font-weight: 640;
            font-size: 30px;
            font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif
        
        }   
        }  

        @media screen and (min-width: 2200px) and (max-width: 3388px) {
          .hero-links > p {
              display: contents;
              text-align: left;
              text-shadow: 2px 2px rgba(2.5, 2.5,  2.5, 2.5);
              color: #fff;
              font-weight: 640;
              font-size: 40px;
              font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif
          
          }   
          } 
        
  

.hero-links:hover {
    border-bottom: 4px solid #4141a4;
    transition: all 0.2s ease-out;
    background-color:rgb(105, 105, 106);
    padding: 0 54px; 
    
  }  

.hero-btns {
    margin-top: 32px;
}

.hero-btns .btn {
    margin: 6px;
}

.fa-play-circle {
    margin-left: 4px;
}





/* @media screen and (max-width: 600px) {
    .hero-container > h1 {
        margin-top: 1px;
        font-size: 30px;
        text-align: center;
    }
} */

@media screen and (max-width: 390) {
    /* .hero-container > h1 {
        font-size: 50px;
        margin-top: -100px;
    } */

  .hero-container > p {
        font-size: 30px;
    }

    .btn-mobile {
        display: block;
        text-decoration: none;
    }

    .btn {
        width: 100%;
    }
}

@media screen and (min-width: 391) {
  .hero-container > h1 {
      font-size: 50px;
      margin-top: -100px;
  }

  .hero-container > p {
      font-size: 30px;
  }

  .btn-mobile {
      display: block;
      text-decoration: none;
  }

  .btn {
      width: 100%;
  }
}


  
  

  @media screen and (min-width: 601px) {
    h5 {
      font-size: 80px;
      text-align: center;
  
    }
  }
  
  @media screen and (max-width: 600px) {
    h5 {
      font-size: 30px;
      text-align: center;
  
    }
  }

  @media screen and (min-width: 601px) {
    h2 {
      font-size: 80px;
      text-align: center;
  
    }
  }
  
  @media screen and (max-width: 600px) {
    h2 {
      font-size: 30px;
      text-align: center;
  
    }
  }

    @media screen and (min-width: 601px) {
    h5 {
      font-size: 80px;
      text-align: left;
  
    }
  }
  
  @media screen and (max-width: 600px) {
    h5 {
      font-size: 30px;
      text-align: left;
  
    }
  }