* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'PT Sans', sans-serif;
}

.home,
.crosscoutrypage,
.downtownferrybassin,
.pipespoolingpage,
.prevousprojectspage,
.projectspage,
.structualweldingpage,
.taraseparationplantpage,
.tauhara2022page,
.weldqualitypage,
.contactpage {
  display: flex;
  height: 90vh;
  align-items: center;
  justify-content: center;
  /* font-size: 3rem; */

}

.crosscountrypage {
  background-image: url('../public/images/geothermal-energy-istock-518613696.png');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
 
}

.downtownferrybassin {
  /* background-image: url('../public/images/geothermal-energy-istock-518613696.png'); */
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

 .pipespoolingpage {
    background-image: url('../public/images/geothermal-energy-istock-518613696.png');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    font-size: 100px;
}

.prevousprojectspage {
  background-image: url('../public/images/geothermal-energy-istock-518613696.png');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  font-size: 100px;
}

.projectspage {
  background-image: url('../public/images/geothermal-energy-istock-518613696.png');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  font-size: 100px;
}

.structualweldingpage {
  /* background-image: url('/images/Geothermalpic.png'); */
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.taraseparationplantpage{
  background-image: url('../public/images/geothermal-energy-istock-518613696.png');
  background-position: center;
  background-size: fill;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.tauhara2022page{
  /* background-image: url('/images/Geothermalpic.png'); */
  background-position: center;
  background-size: fill;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.weldqualitypage{
  /* background-image: url('/images/img-1.jpg'); */
  background-position: center;
  background-size: fill;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.contactpage {
  /* background-image: url('../public/images/RSContracting_FinalBackground.png'); */
  background-position: center;
  background-size: cover;
  background-repeat: repeat;
  color: rgb(100, 117, 135);
  font-size: 100px;
}