.formbackground {
  background-image: url('../../public/images/RSContracting_FinalBackground.png'); 
  /* position: fixed; */
  max-width: 100%;
  /* min-height: 100%; */
  background-size: cover;
  background-position: center;

}

@media screen and (min-width: 320px) and (max-width: 2880px) {
    .form-container {
        margin: 50px auto;
        width: 70%;
        box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.2), 0 7px 20px 0 rgba(0, 0, 0, 0.2);
        position: relative;
        border-radius: 10px;
        height: 500px;
        display: grid ;
        /* grid-template-columns: 1fr; */
      }
    }
  
  @media screen and (min-width: 320px) and (max-width: 2880px) {
    .close-btn {
      position: absolute;
      top: 2%;
      right: 3%;
      font-size: 1.5rem;
      z-index: 1;
      color: #fff;
      cursor: pointer;
    }
  }

  /* @media screen and (max-width: 2880px) {
    .form-content-left {
      background: linear-gradient(
        90deg,
        rgb(194, 39, 255) 0%,
        rgb(0, 232, 236) 100%
      );
      border-radius: 10px 0 0 10px;
      position: relative;
    }
  } */
  
  
  
  /* @media screen and (min-width: 373px) and (max-width: 2880px) {
    .form-img {
      width: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  } */

 
    
  @media screen and (min-width: 320px) and (max-width: 2880px) {
    .form-img-2 {

      width: 45%;
      position: absolute;
      top: 3%;
      right:-12%;
      /* display:flex; */
    }
  }

  @media screen and (min-width: 320px) and (max-width: 2880px) {
    .form-success {
      text-align: center;
      font-size: 24px;
      margin-top: 80px;
      color: #fff;
    }
  }
  @media screen and (min-width: 320px) and (max-width: 2880px) {
    .form-content-right {
      border-radius: 10px 10px 10px 10px;
      position: relative;
      background: linear-gradient(90deg, rgb(40, 40, 40) 0%, rgb(17, 17, 17) 100%);
    }
  }
  @media screen and (min-width: 320px) and (max-width: 2880px) {  
    .form {
      position: absolute;
      top: 50%;
      left: 50%;
      right: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
  @media screen and (min-width: 320px) and (max-width: 2880px) {
    .form h1 {
      font-size: 1.8rem;
      text-align: start;
      width: 80%;
      margin-bottom: 1rem;
      color: #fff;
    }
  }
  @media screen and (min-width: 320px) and (max-width: 2880px) {
    .form-inputs {
      margin-bottom: 0.5rem;
      width: 80%;
    }
  }
    @media screen and (min-width: 320px) and (max-width: 2880px) {
    .form-messages {
      margin-bottom: 0.5rem;
      width: 80%;
    }
  }
  
  @media screen and (min-width: 320px) and (max-width: 2880px) {
    .form-inputs p {
      font-size: 0.8rem;
      margin-top: 1.5rem;
      color: #f00e0e;
    }
  }
  @media screen and (min-width: 320px) and (max-width: 2880px) { 
    .form-label {
      display: inline-block;
      font-size: 0.8rem;
      margin-bottom: 6px;
      color: #fff;
    }
  }
    @media screen and (min-width: 320px) and (max-width: 2880px) {
    .form-input {
      display: block;
      padding-left: 10px;
      outline: none;
      border-radius: 2px;
      height: 40px;
      width: 100%;
      border: none;
    }
  }
  @media screen and (min-width: 320px) and (max-width: 2880px) {
    .form-input::placeholder {
      color: #595959;
      font-size: 12px;
    }
  }
  @media screen and (min-width: 320px) and (max-width: 2880px) {
    .form-message {
      display: block;
      padding-left: 10px;
      padding-top: 10px;
      outline: none;
      border-radius: 2px;
      height: 40px;
      width: 100%;
      border: none;
    }
  }
  @media screen and (min-width: 320px) and (max-width: 2880px) {
    .form-message::placeholder {
      color: #595959;
      font-size: 12px;
    }
  }
  @media screen and (min-width: 320px) and (max-width: 2880px) {
    .form-input-btn {
      width: 80%;
      height: 50px;
      margin-top: 10px;
      border-radius: 2px;
      background: linear-gradient(
        90deg,
        rgb(50, 46, 168) 0%,
        rgb(90, 104, 126) 100%
      );
      outline: none;
      border: none;
      color: #fff;
      font-size: 1rem;
    }
  }
  @media screen and (min-width: 320px) and (max-width: 2880px) {
    .form-input-btn:hover {
      cursor: pointer;
      background: linear-gradient(
        90deg,
        rgb(39, 39, 135) 0%,
        rgb(46, 48, 99) 100%
      );
      transition: all 0.4s ease-out;
    }
  }
    
    @media screen and (min-width: 320px) and (max-width: 2880px) {
  
    .form-container>h1 {
        font-size: 70px;
        margin-top: -150px;
    }
  }
  @media screen and (min-width: 320px) and (max-width: 2880px) {
    .form-container>h1 {
        font-size: 50px;
        margin-top: -100px;
    }
  
    .form-container>p {
        font-size: 30px;
    }
  
    .btn-mobile {
        display: block;
        text-decoration: none;
    }
  
    .btn {
        width: 100%;
    }
  }
  