@import url('https://fonts.googleapis.com/css?family=Audiowide');
@import url('https://fonts.googleapis.com/css?family=Aldrich');
@import url('https://fonts.googleapis.com/css?family=Anton');

.cardsbackground {
  background-image: url('../../public/images/RSContractingLtdEveryPageBG.png'); 
  /* position: fixed; */
  max-width: 100%;
  /* min-height: 100%; */
  background-size: cover;
  background-position: center;

}


.img {
max-width: 100%;
}

.cards {
padding: 2rem;


/* background-image: url('../../public/images/welding-industry-steel-mask.jpg') no-repeat; */


}
  
  h1 {
    font-family: 'Anton';
  align-items: center;  
  font-weight: 50;
  text-align: center;
  color: rgb(0, 0, 0);
    text-align: center;
  }

  h2 {
    text-align: center;
  color: rgb(0, 0, 0);
  font-size: 60px;
  text-shadow: 2px 2px rgba(118, 118, 118, 0.5);

  }


  .cards__container {
    display: flex;
    flex-flow: column;
    align-items: center;
    max-width: 2220px;
    width: 90%;
    margin: 0 auto;
    
  }
  
  .cards__wrapper {
    position: relative;
    margin: 50px 0 45px;
  }
  
  .cards__items {
    margin-bottom: 34px;
  }
  
  .cards__item {
    display: flex;
    flex: 1;
    margin: 0 1rem;
    border-radius: 10px;
    background-color: #fff;
  }
  
  .cards__item__link {
    display: flex;
    flex-flow: column;
    width: 100%;
    box-shadow: 0 6px 20px rgba(56, 125, 255, 0.17);
    -webkit-filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
    filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
    border-radius: 10px;
    overflow: hidden;
    text-decoration: none;
  }
  
  .cards__item__pic-wrap {
    position: relative;
    width: 100%;
    padding-top: 67%;
    overflow: hidden;
  }
  
  .fade-img {
    animation-name: fade-img;
    animation-duration: 2s;
  }

  .cards__item__pic-wrap::after {
    content: attr(data-category);
    position: absolute;
    bottom: 0;
    margin-left: 10px;
    padding: 6px 8px;
    max-width: calc((100%) - 60px);
    font-size: 12px;
    font-weight: 700;
    color: #fff;
    background-color: #353c87;
    box-sizing: border-box;
  }
  
  @media only screen and (min-width: 320px) and (max-width: 480px) and (orientation: landscape)  {
  .cards__item__pic-wrap::after {
    content: attr(data-category);
    position: absolute;
    bottom: 0;
    margin-left: 0;
    padding: 6px 8px;
    max-width: calc((100%) - 60px);
    font-size: 12px;
    font-weight: 700;
    color: #fff;
    background-color: #353c87;
    box-sizing: border-box;
    transform: rotate(90deg);
  }
}

@media only screen and (min-width: 481px) and (max-width: 768px) {
  .cards__item__pic-wrap::after {
    content: attr(data-category);
    position: absolute;
    bottom: 0;
    margin-left: 0;
    padding: 6px 8px;
    max-width: calc((100%) - 60px);
    font-size: 18px;
    font-weight: 700;
    color: #fff;
    background-color: #353c87;
    box-sizing: border-box;
  }
}

@media only screen and (min-width: 769px) and (max-width: 1024px) {
  .cards__item__pic-wrap::after {
    content: attr(data-category);
    position: absolute;
    bottom: 0;
    margin-left: 0;
    padding: 6px 8px;
    max-width: calc((100%) - 60px);
    font-size: 22px;
    font-weight: 700;
    color: #fff;
    background-color: #353c87;
    box-sizing: border-box;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1200px) {
  .cards__item__pic-wrap::after {
    content: attr(data-category);
    position: absolute;
    bottom: 0;
    margin-left: 0;
    padding: 6px 8px;
    max-width: calc((100%) - 60px);
    font-size: 22px;
    font-weight: 700;
    color: #fff;
    background-color: #353c87;
    box-sizing: border-box;
  }
}

@media screen and (min-width: 1201px) and (max-width: 2199px) {
  .cards__item__pic-wrap::after {
    content: attr(data-category);
    position: absolute;
    bottom: 0;
    margin-left: 0;
    padding: 6px 8px;
    max-width: calc((100%) - 60px);
    font-size: 26px;
    font-weight: 700;
    color: #fff;
    background-color: #353c87;
    box-sizing: border-box;
  }
}

@media screen and (min-width: 2200px) and (max-width: 3388px) {
  .cards__item__pic-wrap::after {
    content: attr(data-category);
    position: absolute;
    bottom: 0;
    margin-left: 0;
    padding: 6px 8px;
    max-width: calc((100%) - 60px);
    font-size: 26px;
    font-weight: 700;
    color: #fff;
    background-color: #353c87;
    box-sizing: border-box;
  }
}
  
  .cards__item__img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    width: 100%;
    max-width: 100%;
    height: 100%;
    max-height: 100%;
    object-fit: cover;
    transition: all 0.2s linear;
  }
  
  .cards__item__img:hover {
    transform: scale(1.1);
  }
  

  
  .cards__item__text {
    color: #252e48;
    font-size: 18px;
    line-height: 24px;
  }

  @media only screen and (min-width: 320px) and (max-width: 480px) and (orientation: landscape) {
  .cards__item__text {
    color: #252e48;
    font-size: 14px;
    line-height: 24px;
  }
}

  
  @media only screen and (min-width: 1200px) {
    .content__blog__container {
      width: 84%;
    }
  }
  
  @media only screen and (min-width: 1024px) {
    .cards__items {
      display: flex;
    }
  }
  
  @media only screen and (max-width: 1024px) {
    .cards__item {
      margin-bottom: 2rem;
    }
  }

    
  /* h1 {
    font-family: 'Anton';
  align-items: center;  
  font-weight: 50;
  text-align: center;
  color: rgb(0, 0, 0);
    text-align: center;
  } */
  
  @media only screen and (min-width: 320px) and (max-width: 480px) and (orientation: landscape) {
    .cardsh1 {
      font-family: 'Anton';
      align-items: center;  
      font-weight: 10;
      text-align: center;
      color: rgb(0, 0, 0);
      /* font-family: "Raleway", sans-serif; */
      /* margin-bottom: -10px; */
      margin-top: 20px;
      text-shadow: 2px 2px rgba(118, 118, 118, 0.5);
    }
    }

    @media only screen and (min-width: 481px) and (max-width: 768px) {
      .cardsh1 {
        font-family: 'Anton';
        align-items: center;  
        font-weight: 46;
        text-align: center;
        color: rgb(0, 0, 0);
        /* font-family: "Raleway", sans-serif; */
        /* margin-bottom: -10px; */
        margin-top: 20px;
        text-shadow: 2px 2px rgba(118, 118, 118, 0.5);
      }
      }
    
    @media only screen and (min-width: 769px) and (max-width: 1024px) {
      .cardsh1 {
        font-family: 'Anton';
        align-items: center;  
        font-weight: 46;
        text-align: center;
        color: rgb(0, 0, 0);
        /* font-family: "Raleway", sans-serif; */
        /* margin-bottom: -10px; */
        margin-top: 20px;
        text-shadow: 2px 2px rgba(118, 118, 118, 0.5);
      }
      }

      @media screen and (min-width: 1025px) and (max-width: 1200px) {
        .cardsh1 {
        font-family: 'Anton';
        align-items: center;  
        font-weight: 66;
        font-size: 56px;
        text-align: center;
        color: rgb(0, 0, 0);
        /* font-family: "Raleway", sans-serif; */
        /* margin-bottom: -10px; */
        margin-top: 26px;
        text-shadow: 2px 2px rgba(118, 118, 118, 0.5);
      }
      }

      @media screen and (min-width: 1201px) and (max-width: 2199px)  {
        .cardsh1 {
          font-family: 'Anton';
          align-items: center;  
          font-weight: 66;
          font-size: 56px;
          text-align: center;
          color: rgb(0, 0, 0);
          /* font-family: "Raleway", sans-serif; */
          /* margin-bottom: -10px; */
          margin-top: 26px;
          text-shadow: 2px 2px rgba(118, 118, 118, 0.5);
        }
        }  

        @media screen and (min-width: 2200px) and (max-width: 3388px) {
          .cardsh1 {
            font-family: 'Anton';
            align-items: center;  
            font-weight: 66;
            font-size: 56px;
            text-align: center;
            color: rgb(0, 0, 0);
            /* font-family: "Raleway", sans-serif; */
            /* margin-bottom: -10px; */
            margin-top: 26px;
            text-shadow: 2px 2px rgba(118, 118, 118, 0.5);
          }
          }  
      



.cardsh2 {
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  text-align: center;
  color: rgb(0, 0, 0);
  font-size: 16px;
  text-shadow: 2px 2px rgba(118, 118, 118, 0.5);
}


@media only screen and (min-width: 320px) and (max-width: 480px) and (orientation: landscape) {
  .cardsh2 {
  font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  text-align: center;
  color: rgb(0, 0, 0);
  font-size: 18px;
  text-shadow: 2px 2px rgba(118, 118, 118, 0.5);
}
}

@media only screen and (min-width: 481px) and (max-width: 768px) {
  .cardsh2 {
  font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  text-align: center;
  color: rgb(0, 0, 0);
  font-size: 22px;
  text-shadow: 2px 2px rgba(118, 118, 118, 0.5);
}
}

@media screen and (min-width: 769px) and (max-width: 1024px) {
  .cardsh2 {
  font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  text-align: center;
  color: rgb(0, 0, 0);
  font-size: 42px;
  text-shadow: 2px 2px rgba(118, 118, 118, 0.5);
}
}

@media screen and (min-width: 1025px) and (max-width: 1200px) {
  .cardsh2 {
  font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  text-align: center;
  color: rgb(0, 0, 0);
  font-size: 28px;
  text-shadow: 2px 2px rgba(118, 118, 118, 0.5);
}
}

@media screen and (min-width: 1201px) and (max-width: 2199px) {
  .cardsh2 {
  font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  text-align: center;
  color: rgb(0, 0, 0);
  font-size: 32px;
  text-shadow: 2px 2px rgba(118, 118, 118, 0.5);
}
}

@media screen and (min-width: 2200px) and (max-width: 3388px) {
  .cardsh2 {
  font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  text-align: center;
  color: rgb(0, 0, 0);
  font-size: 42px;
  text-shadow: 2px 2px rgba(118, 118, 118, 0.5);
}
}



  h2 {
    text-align: center;
  color: rgb(0, 0, 0);
  font-size: 60px;
  text-shadow: 2px 2px rgba(118, 118, 118, 0.5);

  }


  .cards__item__img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display:flex;
    width: 100%;
    max-width: 100%;
    height: 100%;
    max-height: 100%;
    object-fit: cover;
    transition: all 0.2s linear;
  }

  .cards__item__imgtwo {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display:flex;
    width: 100%;
    max-width: 100%;
    height: 100%;
    max-height: 100%;
    object-fit: cover;
    transition: all 0.2s linear;
  }

  .cards__item__imgthree {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display:flex;
    width: 100%;
    max-width: 100%;
    height: 100%;
    max-height: 100%;
    object-fit: cover;
    transition: all 0.2s linear;
  }

  .cards__item__imgfour {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display:flex;
    width: 100%;
    max-width: 100%;
    height: 100%;
    max-height: 100%;
    object-fit: cover;
    transition: all 0.2s linear;
  }

  .cards__item__imgfive {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display:flex;
    width: 100%;
    max-width: 100%;
    height: 100%;
    max-height: 100%;
    object-fit: cover;
    transition: all 0.2s linear;
  }

  .cards__item__imgsix {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display:flex;
    width: 100%;
    max-width: 100%;
    height: 100%;
    max-height: 100%;
    object-fit: cover;
    transition: all 0.2s linear;
  }

  .cards__item__imgseven {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display:flex;
    width: 100%;
    max-width: 100%;
    height: 100%;
    max-height: 100%;
    object-fit: cover;
    transition: all 0.2s linear;
  }
  
  .cards__item__img:hover {
    transform: scale(1.1);
  }
  
  .cards__item__info {
    padding: 20px 20px 30px;
  }
  
  @media only screen and (min-width: 320px) and (max-width: 480px) and (orientation: landscape) {
  .cards__item__text {
    color: #252e48;
    font-size: 12px;
    line-height: 24px;
    text-align: justify;
  }
}

@media only screen and (min-width: 481px) and (max-width: 521px) {
  .cards__item__text {
    color: #252e48;
    font-size: 14px;
    line-height: 24px;
    text-align: justify;
  }
}

@media only screen and (min-width: 769px) and (max-width: 820px) {
  .cards__item__text {
    color: #252e48;
    font-size: 16px;
    line-height: 24px;
    text-align: justify;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1084px) {
  .cards__item__text {
    color: #252e48;
    font-size: 15px;
    line-height: 24px;
    text-align: justify;
  }
}

@media screen and (min-width: 1201px) and (max-width: 1281px) {
  .cards__item__text {
    color: #252e48;
    font-size: 17px;
    line-height: 24px;
    text-align: justify;
  }
}

  .cards__item__texttwo {
    color: #252e48;
    font-size: 18px;
    line-height: 24px;
  }

  .cards__item__textthree {
    color: #252e48;
    font-size: 18px;
    line-height: 24px;
  }

  .cards__item__textfour {
    color: #252e48;
    font-size: 18px;
    line-height: 24px;
  }

  .cards__item__textfive {
    color: #252e48;
    font-size: 18px;
    line-height: 24px;
  }

  .cards__item__textsix {
    color: #252e48;
    font-size: 18px;
    line-height: 24px;
  }

  .cards__item__textseven {
    color: #252e48;
    font-size: 18px;
    line-height: 24px;
  }



  
  

  