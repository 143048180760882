@import url('https://fonts.googleapis.com/css?family=Audiowide');
@import url('https://fonts.googleapis.com/css?family=Aldrich');
@import url('https://fonts.googleapis.com/css?family=Anton');
 

    .cardsbackground {
      background-image: url('../../public/images/RSContractingLtdEveryPageBG.png'); 
      /* position: fixed; */
      max-width: 100%;
      /* min-height: 100%; */
      background-size: cover;
      background-position: center;

    }
  

.img {
  max-width: 100%;
}
.cards {
    padding: 2rem;
    /* background-image: url('../../public/images/welding-industry-steel-mask.jpg') no-repeat; */
   
   
  }

  .cardsferrybasin {
    padding: 2rem;
    background-image: url('../../public/images/RSContractingBG.png');
  }

  h1 {
    font-family: 'Anton';
    align-items: center;  
    font-weight: 50;
    text-align: center;
    color: rgb(0, 0, 0);
    /* font-family: "Raleway", sans-serif; */
    /* margin-bottom: -10px; */
    margin-top: 20px;
    text-shadow: 2px 2px rgba(118, 118, 118, 0.5);
  }



    h2 {
    text-align: center;
    color: rgb(0, 0, 0);
    font-size: 20px;
    text-shadow: 2px 2px rgba(118, 118, 118, 0.5);
  
    }
  


  h5 {
    text-align: left;
    color: rgb(0, 0, 0);
    font-size: 18px;
    /* margin-bottom: -60x; */
    /* margin-top: -100px; */
  }
  
  .cards__container {
    display: flex;
    object-fit: cover;
    flex-flow: column;
    align-items: center;
    max-width: 2220px;
    width: 100%;
   

  }
  
  .cards__wrapper {
    position: relative;
    margin: 50px 0 45px;
  }
  
  .cards__carditempages {
    margin-bottom: 24px;
    object-fit: cover;
  }
  
  .cards__carditempages {
    display: flex;
    object-fit: cover;
    flex: 1;
    margin: 0 1rem;
   
  }
  
  .cards__carditempages__link {
    object-fit: cover;
    display: flex;
    flex-flow: column;
    width: 100%;
    background: #fff;
    box-shadow: 10px 10px 10px rgba(36, 44, 62, 0.623);
    -webkit-filter: drop-shadow(10px 10px 10px rgba(36, 44, 62, 0.623));
    filter: drop-shadow(10px 10px 10px rgba(36, 44, 62, 0.623));
    border-radius:0;
    overflow: hidden;
    text-decoration: none;
    /* border: #252e48;
    border-style:solid;
    border-width: px; */
 

    
  }
  
  .cards__carditempages__pic-wrap {
    position: relative;
    width: 100%;
    padding-top: 67%;
    overflow: hidden;
    
    
  }

  .cards__carditempages__pic-wrapzline {
    position: relative;
    width: 100%;
    padding-top: 57%;
    overflow: hidden;
  }
  .cards__carditempages__pic-wrapferrybassin {
    position: relative;
    width: 100%;
    padding-top: 57%;
    overflow: hidden;
  }

  .cards__carditempages__pic-wraptwo {
    position: relative;
    width: 100%;
    padding-top: 28%;
    overflow: hidden;
  }

  .cards__carditempages__pic-wraptwotga {
    position: relative;
    width: 100%;
    padding-top: 28%;
    overflow: hidden;
  }

  .cards__carditempages__pic-wraptwotemihi {
    position: relative;
    width: 100%;
    padding-top: 28%;
    overflow: hidden;
  }

  .cards__carditempages__pic-wrapthree {
    position: relative;
    width: 100%;
    padding-top: 52%;
    overflow: hidden;
  }

  .cards__carditempages__pic-wrapthreetemihi {
    position: relative;
    width: 100%;
    padding-top: 82%;
    overflow: hidden;
  }


  .cards__carditempages__pic-wrapthreeferry {
    position: relative;
    width: 100%;
    padding-top: 27%;
    overflow: hidden;
  }

  .cards__carditempages__pic-wraptauhara {
    position: relative;
    width: 100%;
    padding-top: 97%;
    overflow: hidden;
  }

  .cards__carditempages__pic-wrapthreetga {
    position: relative;
    width: 100%;
    padding-top: 52%;
    overflow: hidden;
  }

  .cards__carditempages__pic-wrapthreetemihi {
    position: relative;
    width: 100%;
    padding-top: 52%;
    overflow: hidden;
  }
  
  .cards__carditempages__pic-wrapfour {
    position: relative;
    width: 100%;
    padding-top: 52%;
    overflow: hidden;
  }

  .cards__carditempages__pic-wrapfourtga {
    position: relative;
    width: 100%;
    padding-top: 52%;
    overflow: hidden;
  }

  .cards__carditempages__pic-wrapfourtga2 {
    position: relative;
    width: 100%;
    padding-top: 22%;
    overflow: hidden;
  }

  .cards__carditempages__pic-wrapfourtemihi {
    position: relative;
    width: 100%;
    padding-top: 52%;
    overflow: hidden;
  }

  .cards__carditempages__pic-wrapfourngawha {
    position: relative;
    width: 100%;
    padding-top: 20%;
    overflow: hidden;
  }

  .fade-img {
    animation-name: fade-img;
    animation-duration: 2s;
  }
  
  .cards__carditempages__pic-wrap::after {
    content: attr(data-category);
    position: absolute;
    bottom: 0;
    margin-left: 0px;
    padding: 6px 8px;
    max-width: calc((80s) - 60px);
   
  }
  
  .cards__carditempages__img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display:flex;
    width: 100%;
    max-width: 100%;
    height: auto;
    max-height: auto;
    object-fit: cover;
    transition: all 0.2s linear;

  }

  .cards__carditempages__imgtga {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display:flex;
    width: 100%;
    max-width: 100%;
    height: auto;
    max-height: auto;
    object-fit: cover;
    transition: all 0.2s linear;
  }

  .cards__carditempages__imgtemihi {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display:flex;
    width: 100%;
    max-width: 100%;
    height: auto;
    max-height: auto;
    object-fit: cover;
    transition: all 0.2s linear;
  }

  .cards__carditempages__imgtwo {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display:flex;
    width: 100%;
    max-width: 100%;
    height: auto;
    max-height: auto;
    object-fit: cover;
    transition: all 0.2s linear;
  }

  .cards__carditempages__imgtwotga {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display:flex;
    width: 100%;
    max-width: 100%;
    height: auto;
    max-height: auto;
    object-fit: cover;
    transition: all 0.2s linear;
  }

  .cards__carditempages__imgtwotemihi {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display:flex;
    width: 100%;
    max-width: 100%;
    height: auto;
    max-height: auto;
    object-fit: cover;
    transition: all 0.2s linear;
  }

  .cards__carditempages__imgthree {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display:flex;
    width: 100%;
    max-width: 100%;
    height: auto;
    max-height: auto;
    object-fit: cover;
    transition: all 0.2s linear;
  }

  .cards__carditempages__imgthreetga {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display:flex;
    width: 100%;
    max-width: 100%;
    height: auto;
    max-height: auto;
    object-fit: cover;
    transition: all 0.2s linear;
  }

  .cards__carditempages__imgthreetemihi {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display:flex;
    width: 100%;
    max-width: 100%;
    height: auto;
    max-height: auto;
    object-fit: cover;
    transition: all 0.2s linear;
  }

  .cards__carditempages__imgfour {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display:flex;
    width: 100%;
    max-width: 100%;
    height: auto;
    max-height: auto;
    object-fit: cover;
    transition: all 0.2s linear;
  }

  .cards__carditempages__imgfourtga {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display:flex;
    width: 100%;
    max-width: 100%;
    height: auto;
    max-height: auto;
    object-fit: cover;
    transition: all 0.2s linear;
  }

  .cards__carditempages__imgfourngawha {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display:flex;
    width: 100%;
    max-width: 100%;
    height: auto;
    max-height: auto;
    object-fit: cover;
    transition: all 0.2s linear;
  }

  .cards__carditempages__imgfive {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display:flex;
    width: 100%;
    max-width: 100%;
    height: auto;
    max-height: auto;
    object-fit: cover;
    transition: all 0.2s linear;
  }

  .cards__carditempages__imgfive {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display:flex;
    width: 100%;
    max-width: 100%;
    height: auto;
    max-height: auto;
    object-fit: cover;
    transition: all 0.2s linear;
  }

  .cards__carditempages__imgsix {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display:flex;
    width: 100%;
    max-width: 100%;
    height: auto;
    max-height: auto;
    object-fit: cover;
    transition: all 0.2s linear;
  }
  
  .cards__carditempages__img:hover {
    transform: scale(1.1);
  }

  .cards__carditempages__imgtwo:hover {
    transform: scale(1.1);
  }
  
  
  .cards__carditempages__info {
    padding: 20px 60px 0px;
  }


  .cards__carditempages__infolist {
    margin-left: 140px;
    margin-top: -15px;
    column-count: 4;
    column-gap: 30px;
  }

  .cards__carditempages__infolisttga {
    margin-left: 140px;
    margin-top: -15px;
    column-count: 4;
    column-gap: 30px;
  }

  .cards__carditempages__infolisttwo {
    margin-left: 50px;
    margin-right: 40px;
    margin-top: -15px;
    column-count: 3;
    column-gap: 60px;
  }

   .cards__carditempages__infolisttwotga {
    margin-left: 50px;
    margin-right: 40px;
    margin-top: -15px;
    /* column-count: 3; */
    column-gap: 60px;
  }

  .cards__carditempages__text {
    color: #252e48;
    font-size: 16px;
    line-height: 24px;
    margin-left: -30px;
    margin-right: -30px;
    text-align:justify;


  }

  .cards__carditempages__texttga {
    color: #252e48;
    font-size: 20px;
    line-height: 24px;
    margin-left: -30px;
    margin-right: -30px;
    text-align:justify;

  }


  .cards__carditempages__texttwo {
    color: #252e48;
    font-size: 16px;
    line-height: 24px;
    margin-left: -30px;
    margin-right: -30px;
    text-align:justify;
    
    

  }

  .cards__carditempages__texttwonga {
    color: #252e48;
    font-size: 16px;
    line-height: 24px;
    margin-left: -30px;
    margin-right: -30px;
    text-align:left;
    
    

  }

  .cards__carditempages__texttwotga {
    color: #252e48;
    font-size: 16px;
    line-height: 24px;
    margin-left: -30px;
    margin-right: -30px;
    text-align:justify;
   
  }

  .cards__carditempages__texttwokaw {
    color: #252e48;
    font-size: 16px;
    line-height: 24px;
    margin-left: -30px;
    margin-right: -30px;
    text-align:justify;
   
  }

  .cards__carditempages__texttwozline {
    color: #252e48;
    font-size: 14px;
    line-height: 24px;
    margin-left: -30px;
    margin-right: -30px;
    text-align:justify;
   
  }

  .cards__carditempages__textthree {
    color: #252e48;
    font-size: 16px;
    line-height: 24px;
    margin-left: -30px;
    margin-right: -30px;
    text-align:justify;


  }

  .cards__carditempages__textthreenga {
    color: #252e48;
    font-size: 16px;
    line-height: 24px;
    margin-left: -30px;
    margin-right: -30px;
    text-align:left;


  }

  .cards__carditempages__textthreekaw {
    color: #252e48;
    font-size: 16px;
    line-height: 24px;
    margin-left: -30px;
    margin-right: -30px;
    text-align:left;


  }

  .cards__carditempages__textthreetga {
    color: #252e48;
    font-size: 16px;
    line-height: 24px;
    margin-left: -30px;
    margin-right: -30px;
    text-align:justify;
  }

  .cards__carditempages__textthreezline {
    color: #252e48;
    font-size: 14px;
    line-height: 24px;
    margin-left: -30px;
    margin-right: -30px;
    text-align:justify;
  }

  .cards__carditempages__textfour {
    color: #252e48;
    font-size: 16px;
    line-height: 24px;
    margin-left: -30px;
    margin-right: -30px;
    text-align:justify;

  }

  .cards__carditempages__textfournga {
    color: #252e48;
    font-size: 16px;
    line-height: 24px;
    margin-left: -30px;
    margin-right: -30px;
    text-align:left;

  }

  .cards__carditempages__textfourzline {
    color: #252e48;
    font-size: 14px;
    line-height: 24px;
    margin-left: -30px;
    margin-right: -30px;
    text-align:left;

  }

  .cards__carditempages__textfourtga {
    color: #252e48;
    font-size: 16px;
    line-height: 24px;
    margin-left: -30px;
    margin-right: -30px;
    text-align:justify;
  }

  .cards__carditempages__textfive {
    color: #252e48;
    font-size: 16px;
    line-height: 24px;
    margin-left: -30px;
    margin-right: -30px;
    text-align:justify;

  }

  .cards__carditempages__textfivenga {
    color: #252e48;
    font-size: 16px;
    line-height: 24px;
    margin-left: -30px;
    margin-right: -30px;
    text-align:justify;

  }
  .cards__carditempages__textfivetitle {
    font-family: 'Anton';
    color: #2e3c49;
    font-size: 20px;
    line-height: 24px;
    margin-left: -30px;
    margin-right: -30px;
    text-align:justify;

  }

  .cards__carditempages__textfivetitletwo {
    font-family: 'Anton';
    color: #2e3c49;
    font-size: 20px;
    line-height: 24px;
    margin-left: -30px;
    margin-right: -30px;
    text-align:justify;

  }

  .cards__carditempages__textfivetga {
    color: #252e48;
    font-size: 16px;
    line-height: 24px;
    margin-left: -30px;
    margin-right: -30px;
    text-align:justify;
  }

  .cards__carditempages__textfivezline {
    color: #252e48;
    font-size: 14px;
    line-height: 24px;
    margin-left: -30px;
    margin-right: -30px;
    text-align:justify;
  }


  .cards__carditempages__textsix {
    color: #252e48;
    font-size: 16px;
    line-height: 24px;
    margin-left: -30px;
    margin-right: -30px;
    text-align:justify;

  }

  .cards__carditempages__textsixnga {
    color: #252e48;
    font-size: 16px;
    line-height: 24px;
    margin-left: -30px;
    margin-right: -30px;
    text-align:justify;

  }

  .cards__carditempages__textsixzline {
    color: #252e48;
    font-size: 14px;
    line-height: 24px;
    margin-left: -30px;
    margin-right: -30px;
    text-align:justify;

  }


  .cards__carditempages__textseven {
    color: #252e48;
    font-size: 16px;
    line-height: 24px;
    margin-left: -30px;
    margin-right: -30px;
    text-align:justify;

  }
  .cards__carditempages__textsevennga {
    color: #252e48;
    font-size: 16px;
    line-height: 24px;
    margin-left: -30px;
    margin-right: -30px;
    text-align:justify;

  }


.cards__carditempages__textsevenzline {
  color: #252e48;
  font-size: 14px;
  line-height: 24px;
  margin-left: -30px;
  margin-right: -30px;
  text-align:justify;

}


  .cards__carditempages__texteight {
    color: #252e48;
    font-size: 16px;
    line-height: 24px;
    margin-left: -30px;
    margin-right: -30px;
    text-align:justify;

  }

  .cards__carditempages__texteightnga {
    color: #252e48;
    font-size: 16px;
    line-height: 24px;
    margin-left: -30px;
    margin-right: -30px;
    text-align:justify;

  }

  .cards__carditempages__texteightzline {
    color: #252e48;
    font-size: 14px;
    line-height: 24px;
    margin-left: -30px;
    margin-right: -30px;
    text-align:justify;

  }

  .cards__carditempages__textnine {
    color: #252e48;
    font-size: 16px;
    line-height: 24px;
    margin-left: -30px;
    margin-right: -30px;
    text-align:justify;
  }

  .cards__carditempages__textten {
    color: #252e48;
    font-size: 16px;
    line-height: 24px;
    margin-left: -30px;
    margin-right: -30px;
    text-align:justify;
  }


  .cards__carditempages__list {
    color: #252e48;
    display: list-item;
    font-size: 18px;
    line-height: 24px;
  }

  .cards__carditempages__listngawha {
    color: #252e48;
    display: list-item;
    font-size: 18px;
    line-height: 24px;
  }
  .cards__carditempages__listone {
    color: #252e48;
    display: list-item;
    font-size: 18px;
    line-height: 24px;
  }

  .cards__carditempages__listtwo {
    color: #252e48;
    display: list-item;
    font-size: 18px;
    line-height: 24px;
  }

  .cards__carditempages__listthree {
    color: #252e48;
    display: list-item;
    font-size: 18px;
    line-height: 24px;
  }

  .cards__carditempages__listfour {
    color: #252e48;
    display: list-item;
    font-size: 18px;
    line-height: 24px;
  }

  .cards__carditempages__listfive {
    color: #252e48;
    display: list-item;
    font-size: 18px;
    line-height: 24px;
  }

  .cards__carditempages__listsix {
    color: #252e48;
    display: list-item;
    font-size: 18px;
    line-height: 24px;
  }
  
  
  
  /* @media only screen and (min-width: 1024px) {
    .content__blog__container {
      width: 84%;
    }
  } */
  /* @media only screen and (min-width: 601px) {
    .content__blog__container {
      width: 84%;
    }
  } */
  
  /* @media only screen and (min-width: 391px) {
    .cards__carditempages {
      display: flex;
    }
  } */
  
  @media only screen and (max-width: 600px) {
    .cards__carditempages {
      margin-bottom: 2rem;
    }
  }

  @media only screen and (max-width: 600px) {
  .cards__carditempages__info {
    padding: 15px 50px 0px;
  }
}

  /* @media screen and (min-width: 391px) {
    .cardsbackground {
      width: 100%;
    height: auto;
  
    }
  }

  @media screen and (max-width: 390px) {
    .cardsbackground {
      width: 30%;
    height: auto;
  
    }
  } */

  /* @media screen and (min-width: 391px) {
    h1 {
      font-size: 40px;
      text-align: center;
  
    }
  } */



  @media screen and (max-width: 320px) {
    h1 {
      margin-top: 30px;
      font-size: 30px;
      text-align: center;
   

  
    }
  }



  /* @media screen and (min-width: 391px) {
    h5 {
      font-size: 20px;
      text-align: center;
  
    }
  } */
  
  @media screen and (max-width: 600px) {
    h5 {
      font-size: 30px;
      text-align: center;
  
    }
  }

  /* @media screen and (min-width: 391px) {
    h2 {
      font-size: 40px;
      text-align: center;
  
    }
  } */
  
  @media screen and (max-width: 600px) {
    h2 {
      font-size: 20px;
      text-align: center;
  
    }
  }

    /* @media screen and (min-width: 391px) {
    h5 {
      font-size: 80px;
      text-align: left;
  
    }
  } */
  
  @media screen and (max-width: 600px) {
    h5 {
      font-size: 30px;
      text-align: left;
  
    }
  }
  @media screen and (max-width: 600px) {
    .cards__carditempages__text {
      color: #252e48;
      font-size: 11.5px;
      line-height: 20px;
    }
  }

  /* @media screen and (min-width: 391px) {
    .cards__carditempages__text {
      color: #252e48;
      font-size: 20px;
      line-height: 24px;
    }
  } */

  @media screen and (max-width: 600px) {
  .cards__carditempages__texttwo {
    color: #252e48;
    font-size: 11.5px;
    line-height: 20px;
  }
}

@media screen and (max-width: 600px) {
.cards__carditempages__texttwozline {
  color: #252e48;
  font-size: 10px;
  line-height: 20px;
}
}

/* @media screen and (min-width: 391px) {
  .cards__carditempages__texttwo {
    color: #252e48;
    font-size: 20px;
    line-height: 24px;
  }
} */

@media screen and (max-width: 600px) {
  .cards__carditempages__textthree {
    color: #252e48;
    font-size: 11.5px;
    line-height: 20px;
  }
}

@media screen and (max-width: 600px) {
  .cards__carditempages__textthreezline {
    color: #252e48;
    font-size: 10px;
    line-height: 20px;
  }
  }

/* @media screen and (min-width: 391px) {
  .cards__carditempages__textthree {
    color: #252e48;
    font-size: 20px;
    line-height: 24px;
  }
} */

@media screen and (max-width: 600px) {
  .cards__carditempages__textfour {
    color: #252e48;
    font-size: 11.5px;
    line-height: 20px;
  }
}

/* @media screen and (min-width: 391px) {
  .cards__carditempages__textfour {
    color: #252e48;
    font-size: 20px;
    line-height: 24px;
  }
} */

@media screen and (max-width: 600px) {
  .cards__carditempages__textfive {
    color: #252e48;
    font-size: 11.5px;
    line-height: 20px;
  }
}

/* @media screen and (min-width: 391px) {
  .cards__carditempages__textfive {
    color: #252e48;
    font-size: 20px;
    line-height: 24px;
  }
} */

@media screen and (max-width: 600px) {
  .cards__carditempages__textsix {
    color: #252e48;
    font-size: 11.5px;
    line-height: 20px;
    text-align:justify;
  }
}

/* @media screen and (min-width: 391px) {
  .cards__carditempages__textsix {
    color: #252e48;
    font-size: 20px;
    line-height: 24px;
  }
} */

@media screen and (max-width: 600px) {
  .cards__carditempages__textseven {
    color: #252e48;
    font-size: 11.5px;
    line-height: 20px;
  }
}

/* @media screen and (min-width: 391px) {
  .cards__carditempages__textseven {
    color: #252e48;
    font-size: 20px;
    line-height: 24px;
  }
} */

@media screen and (max-width: 600px) {
  .cards__carditempages__texteight {
    color: #252e48;
    font-size: 11.5px;
    line-height: 20px;
  }
}

/* @media screen and (min-width: 391px) {
  .cards__carditempages__texteight {
    color: #252e48;
    font-size: 20px;
    line-height: 24px;
  }
} */


@media screen and (max-width: 600px) {
  .cards__carditempages__textnine {
    color: #252e48;
    font-size: 11.5px;
    line-height: 20px;
  }
}

/* @media screen and (min-width: 391px) {
  .cards__carditempages__textnine {
    color: #252e48;
    font-size: 20px;
    line-height: 24px;
  }
} */

@media screen and (max-width: 600px) {
  .cards__carditempages__textten {
    color: #252e48;
    font-size: 11.5px;
    line-height: 20px;
  }
}
/* 
@media screen and (min-width: 391px) {
  .cards__carditempages__textten {
    color: #252e48;
    font-size: 20px;
    line-height: 24px;
  }
} */

@media screen and (max-width: 600px) {
.cards__carditempages__textfivetitle {
  font-family: 'Anton';
  color: #2e3c49;
  font-size: 14px;
  line-height: 20px;
  margin-left: -30px;
  margin-right: -30px;
  text-align:justify;
}
}

/* @media screen and (min-width: 391px) {
  .cards__carditempages__textfivetitle {
    font-family: 'Anton';
    color: #2e3c49;
    font-size: 20px;
    line-height: 24px;
    margin-left: -30px;
    margin-right: -30px;
    text-align:justify;
  }
  } */

  @media screen and (max-width: 600px) {
.cards__carditempages__textfivetitletwo {
  font-family: 'Anton';
  color: #2e3c49;
  font-size: 14px;
  line-height: 20px;
  margin-left: -30px;
  margin-right: -30px;
  text-align:justify;
}
}

/* @media screen and (min-width: 391px) {
  .cards__carditempages__textfivetitletwo {
    font-family: 'Anton';
    color: #2e3c49;
    font-size: 20px;
    line-height: 24px;
    margin-left: -30px;
    margin-right: -30px;
    text-align:justify;
  }
  } */



